import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import { Container, Paper } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import Image from "../components/image"

const useStyles = makeStyles(() => ({
  phrase: {
    color: "gray",
    textAlign: 'center',
    paddingTop: 150,
    marginLeft: 30,
    marginBottom: 50,
    marginRight: 30,
  },
  system: {
    backgroundColor: "ghostwhite",
    marginTop: 150,
    paddingBottom: 150,

  }
}))


const OverviewPage = (props) => {
  const breakpoints = useBreakpoint();
  const classes = useStyles();
  return(
    <Layout location={props.location} breakpoints = {breakpoints}>
      <Seo title="概要" />
      <div  style={{position: "fixed", top: 0, minWidth: "100%", height: "40vh", zIndex: -1,backgroundColor: "gainsboro"}}><Image filename="overview_background.png" style={{height: "inherit"}}/></div>
      <div className={classes.phrase} style = {{color: "white", paddingBottom: 150, fontSize: breakpoints?.sm ? 28 : breakpoints.md ? 36 : 42}}>
        稲作SaaSの目的
      </div>
      <div style={{backgroundColor: "white", paddingTop: 50}}>

        <Container fix>
        <div
          data-sal="slide-up"
          data-sal-duration="1000" // changes duration of the animation (from 200 to 2000 ms)
          data-sal-delay="10" // adds delay to the animation (from 5 to 1000 ms)
          data-sal-easing="ease" // 
          style = {{fontSize: breakpoints?.sm ? 18 : breakpoints.md ? 24 : 28, paddingLeft: "5vw", paddingRight: "5vw"}}
        ><Paper variant="outlined">
          <p 
          data-sal="slide-up"
          data-sal-duration="1000" // changes duration of the animation (from 200 to 2000 ms)
          data-sal-delay="200" // adds delay to the animation (from 5 to 1000 ms)
          data-sal-easing="ease" // 
          style = {{paddingLeft: "2vw", paddingRight: "2vw"}}>日本の財産である「稲作技術(=高品質の米を作る技術)」 をさらに進化させ、継承可能にする</p>
          <p 
          data-sal="slide-up"
          data-sal-duration="1000" // changes duration of the animation (from 200 to 2000 ms)
          data-sal-delay="400" // adds delay to the animation (from 5 to 1000 ms)
          data-sal-easing="ease" // 
          style = {{paddingLeft: "2vw", paddingRight: "2vw"}}>省力化し、収穫量を増やし、品質も向上させる</p>
          <p 
          data-sal="slide-up"
          data-sal-duration="1000" // changes duration of the animation (from 200 to 2000 ms)
          data-sal-delay="600" // adds delay to the animation (from 5 to 1000 ms)
          data-sal-easing="ease" // 
          style = {{paddingLeft: "2vw", paddingRight: "2vw"}}>稲作ビジネスを盛り上げる　(稲作技術者・稼働水田の増加)</p>
          <p 
          data-sal="slide-up"
          data-sal-duration="1000" // changes duration of the animation (from 200 to 2000 ms)
          data-sal-delay="600" // adds delay to the animation (from 5 to 1000 ms)
          data-sal-easing="ease" // 
          style = {{paddingLeft: "2vw", paddingRight: "2vw"}}>米の品質・味を向上させ、「地域ブランド米」を増やす</p>
        </Paper>
        </div>
      </Container>

      <div className={classes.system}>
        <div 
          data-sal="slide-up"
          data-sal-duration="1000" // changes duration of the animation (from 200 to 2000 ms)
          data-sal-delay="10" // adds delay to the animation (from 5 to 1000 ms)
          data-sal-easing="ease" // 
          className={classes.phrase} style = {{fontSize: breakpoints?.sm ? 28 : breakpoints.md ? 36 : 42}}>
          システム構成
        </div>
        <Container fix>
          <div
            data-sal="slide-up"
            data-sal-duration="1000" // changes duration of the animation (from 200 to 2000 ms)
            data-sal-delay="500" // adds delay to the animation (from 5 to 1000 ms)
            data-sal-easing="ease" // 
            style = {{fontSize: breakpoints?.sm ? 22 : breakpoints.md ? 24 : 28, paddingLeft: "5vw", paddingRight: "5vw"}}
          >
            <Image filename="overview.png" />
            
          </div>
          <div
            data-sal="slide-up"
            data-sal-duration="1000" // changes duration of the animation (from 200 to 2000 ms)
            data-sal-delay="1000" // adds delay to the animation (from 5 to 1000 ms)
            data-sal-easing="ease" //  
            style={{marginTop: "4vh",marginBottom: "5vh"}}>
            <Paper variant="outlined">
              <p style = {{paddingLeft: "2vw", paddingRight: "2vw",textAlign: "center",fontSize: breakpoints?.sm ? 16 : breakpoints.md ? 20 : 24}}>
                稲作水田の環境データの収集に適した 【統合ＩｏＴセンサー】を活用<br/>
                ＬＰＷＡの中でも本サービスに最適な無線技術である【ＬｏＲａＷＡＮ】を活用<br/>
                水田用統合センサーは水田の環境データを1時間毎に自動送信し、データはIBM Cloud上に蓄積<br/>
                分析システム・ＡＩ　を活用しビックデータを分析して提言・提案<br/>
              </p>
            </Paper>
          </div>
        </Container>
        </div>
      </div>
    </Layout>
  )
}

export default OverviewPage
